import React from 'react';

function AGB() {
  return (
    <div>
      <h1>Allgemeine Geschäftsbedingungen (AGB)</h1>
      <h3>1. GELTUNGSBEREICH</h3>
      <p>Für alle Bestellungen über unseren Online-Shops gelten die nachfolgenden AGB.</p>
      <h3>2. VERTRAGSPARTNER, VERTRAGSSCHLUSS, KORREKTURMÖGLICHKEITEN</h3>
      <h4>2.1 VERTRAGSPARTNER</h4>
      <p>Der Kaufvertrag kommt zustande mit Ayam sp z. o.o.</p>
      <h4>2.2 VERTRAGSSCHLUSS</h4>
      <p>
        Wenn wir einen Artikel im Auktions- oder Festpreisformat einstellen, geben wir ein verbindliches Angebot zum Abschluss eines Vertrags über diesen Artikel ab. Dabei bestimmen wir den Start- bzw. Festpreis und die Angebotsdauer, also die Frist, binnen derer das Angebot angenommen werden kann. Weitere Informationen zum Vertragsschluss finden Sie bei den einzelnen Formaten: Auktionsformat Legen wir beim Auktionsformat einen Mindestpreis fest, so steht das Angebot unter der aufschiebenden Bedingung, dass der Mindestpreis erreicht wird.
        Angebote im Auktionsformat können wir zusätzlich mit einer Sofort-Kaufen-Funktion versehen, die Sie ausüben können, solange noch kein Gebot auf den Artikel abgegeben oder der Mindestpreis noch nicht erreicht wurde. Beim Auktionsformat nehmen Sie das Angebot durch Abgabe eines Gebots an. Die Annahme erfolgt unter der aufschiebenden Bedingung, dass Sie nach Ablauf der Angebotsdauer Höchstbietender sind. Ihr Gebot erlischt, wenn ein anderer Käufer während der Angebotsdauer ein höheres Gebot abgibt. Festpreisformat Wenn wir Artikel im Festpreisformat einstellen, geben wir ein verbindliches Angebot zum Abschluss eines Vertrags über diesen Artikel ab.
        Sie nehmen das Angebot an, indem Sie den Button „Sofort-Kaufen“ anklicken und anschließend bestätigen. Bei Festpreisartikeln, bei denen wir die Option „Sofortige Bezahlung“ ausgewählt haben, nehmen Sie das Angebot an, indem Sie den Button „Sofort-Kaufen“ anklicken und den unmittelbar nachfolgenden Zahlungsvorgang abschließen. Sie können Angebote für mehrere Artikel auch dadurch annehmen, dass Sie die Artikel (sofern verfügbar) in den Warenkorb legen und den unmittelbar anschließenden Zahlungsvorgang abschließen. Option Preisvorschlag Wenn in einem unserer Angebote unter dem Sofort-Kaufen-Preis die Schaltfläche „Preisvorschlag senden“ angezeigt wird, können Sie uns einen Preis vorschlagen, zu dem Sie den Artikel kaufen würden.
        Wir können Ihren Preisvorschlag akzeptieren, ihn ablehnen oder ein Gegenangebot unterbreiten. Wenn Sie uns einen Preisvorschlag unterbreiten, geben Sie ein verbindliches Angebot zum Abschluss eines Vertrags über diesen Artikel zu dem von Ihnen vorgeschlagenen Preis ab. Ihr Preisvorschlag ist höchstens 48 Stunden lang oder bis zum Angebotsende gültig, je nachdem, welcher Zeitpunkt zuerst erreicht wird. Nehmen wir ihn an, kommt ein verbindlicher Vertrag zustande. Der Preisvorschlag bezieht sich grundsätzlich nur auf den Preis für den angebotenen Artikel.
      </p>
      <h4>2.3 KORREKTURMÖGLICHKEITEN</h4>
      <p>
        Sie können Ihre Eingaben vor Abgabe der Bestellung mittels der von eBay im Bestellprozess zur Verfügung gestellten und selbsterklärenden Mittel erkennen und korrigieren. Um eine Transaktion abzubrechen, betätigen Sie die „Zurück“-Schaltfläche Ihres Browsers, um wieder auf die Artikelseite oder zum eBay-Warenkorb zu gelangen. Im Warenkorb können Sie durch Änderung der Stückzahl die Menge der Produkte ändern bzw. durch Klick auf „Entfernen“ den Artikel aus dem Warenkorb löschen. Eingabefehler können auch durch Abbruch des Kaufvorgangs korrigiert werden.
      </p>
      <h3>3. LIEFERBEDINGUNGEN</h3>
      <p>
        Zuzüglich zu den angegebenen Produktpreisen können noch Versandkosten anfallen. Nähere Bestimmungen zu ggf. anfallenden Versandkosten erfahren Sie bei den Angeboten. Wir liefern nur im Versandweg. Eine Selbstabholung der Ware ist leider nicht möglich.
      </p>
      <h3>4. BEZAHLUNG </h3>
      <p>
        In unserem Shop stehen Ihnen grundsätzlich die folgenden Zahlungsarten zur Verfügung. Die Zahlung wird durch eBay abgewickelt: Apple Pay Um den Rechnungsbetrag über den Zahlungsdienstleister Apple Inc., One Apple Park Way, Cupertino, CA 95014, USA („Apple“) bezahlen zu können, müssen Sie den Browser „Safari“ nutzen, bei Apple registriert sein, die Funktion Apple Pay aktiviert haben, sich mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung bestätigen. Die Zahlungstransaktion wird unmittelbar nach Abgabe der Bestellung durchgeführt. Weitere Hinweise erhalten Sie im Bestellvorgang. Google Pay Um den Rechnungsbetrag über den Zahlungsdienstleister Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Irland („Google“) bezahlen zu können, müssen Sie bei Google registriert sein, die Funktion Google Pay aktiviert haben, sich mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung bestätigen. Die Zahlungstransaktion wird unmittelbar nach Abgabe der Bestellung durchgeführt. Weitere Hinweise erhalten Sie im Bestellvorgang. Kreditkarte Im Bestellprozess geben Sie Ihre Kreditkartendaten an. Ihre Karte wird unmittelbar nach Abgabe der Bestellung belastet. PayPal Um den Rechnungsbetrag über den Zahlungsdienstleister PayPal (Europe) S.à r.l. et Cie, S.C.A, 22-24 Boulevard Royal, L-2449 Luxembourg („PayPal“) bezahlen zu können, müssen Sie bei PayPal registriert sein, sich mit Ihren Zugangsdaten legitimieren und die Zahlungsanweisung bestätigen. Die Zahlungstransaktion wird durch PayPal unmittelbar nach Abgabe der Bestellung durchgeführt. Weitere Hinweise erhalten Sie im Bestellvorgang.
      </p>
      <h3>5. WIDERRUFSRECHT</h3>
      <p>
        Ihnen steht das gesetzliche Widerrufsrecht, wie in der Widerrufsbelehrung beschrieben, zu.
      </p>
      <h3>6. EIGENTUMSVORBEHALT</h3>
      <p>
        Die Ware bleibt bis zur vollständigen Bezahlung unser Eigentum.
      </p>
      <h3>7. TRANSPORTSCHÄDEN</h3>
      <p>
        Werden Waren mit offensichtlichen Transportschäden angeliefert, so reklamieren Sie solche Fehler bitte möglichst sofort beim Zusteller und nehmen Sie bitte unverzüglich Kontakt zu uns auf. Die Versäumung einer Reklamation oder Kontaktaufnahme hat für Ihre gesetzlichen Ansprüche und deren Durchsetzung, insbesondere Ihre Gewährleistungsrechte, keinerlei Konsequenzen. Sie helfen uns aber, unsere eigenen Ansprüche gegenüber dem Frachtführer bzw. der Transportversicherung geltend machen zu können.
      </p>
      <h3>8. GEWÄHRLEISTUNG UND GARANTIEN</h3>
      <h4>8.1 MÄNGELHAFTUNGSRECHT</h4>
      <p>
        Es gilt das gesetzliche Mängelhaftungsrecht.
      </p>
      <h4>8.2 GARANTIEN UND KUNDENDIENST</h4>
      <p>
        Informationen zu gegebenenfalls geltenden zusätzlichen Garantien und deren genaue Bedingungen finden Sie jeweils beim Produkt und auf besonderen Informationsseiten im Online-Shop. Kundendienst: Sie erreichen unseren Kundendienst für Fragen, Reklamationen und Beanstandungen per E-Mail unter <b>support@ayam.green</b>
      </p>
      <h3>9. HAFTUNG</h3>
      <p>
        Für Ansprüche aufgrund von Schäden, die durch uns, unsere gesetzlichen Vertreter oder Erfüllungsgehilfen verursacht wurden, haften wir stets unbeschränkt • bei Verletzung des Lebens, des Körpers oder der Gesundheit • bei vorsätzlicher oder grob fahrlässiger Pflichtverletzung • bei Garantieversprechen, soweit vereinbart, oder • soweit der Anwendungsbereich des Produkthaftungsgesetzes eröffnet ist. Bei Verletzung wesentlicher Vertragspflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertrauen darf, (Kardinalpflichten) durch leichte Fahrlässigkeit von uns, unseren gesetzlichen Vertretern oder Erfüllungsgehilfen ist die Haftung der Höhe nach auf den bei Vertragsschluss vorhersehbaren Schaden begrenzt, mit dessen Entstehung typischerweise gerechnet werden muss. Im Übrigen sind Ansprüche auf Schadensersatz ausgeschlossen.
      </p>
      <h3>10. STREITBEILEGUNG</h3>
      <p>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden. Verbraucher haben die Möglichkeit, diese Plattform für die Beilegung ihrer Streitigkeiten zu nutzen. Zur Beilegung von Streitigkeiten aus einem Vertragsverhältnis mit einem Verbraucher bzw. darüber, ob ein solches Vertragsverhältnis überhaupt besteht, sind wir zur Teilnahme an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle verpflichtet.
      </p>
      <h3>11. JUGENDSCHUTZ</h3>
      <p>
        Sofern Ihre Bestellung Waren umfasst, deren Verkauf Altersbeschränkungen unterliegt, stellen wir durch den Einsatz eines zuverlässigen Verfahrens unter Einbeziehung einer persönlichen Identitäts- und Altersprüfung sicher, dass der Besteller das erforderliche Mindestalter erreicht hat. Der Zusteller übergibt die Ware erst nach erfolgter Altersprüfung und nur an den Besteller persönlich. Eine Zustellung an Packstationen ist in diesem Fall nicht möglich.
      </p>
      <h3>13. Widerrufsbelehrung</h3>
      <p>
        Verbraucher haben ein einmonatiges Widerrufsrecht.
      </p>

      <h4>Widerrufsrecht</h4>
      <p>
        Sie haben das Recht, binnen eines Monats ohne Angabe von Gründen diesen Vertrag zu widerrufen.
      </p>
      <p>
        Die Widerrufsfrist beträgt einen Monat ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Teilsendung oder das letzte Stück in Besitz genommen haben bzw. hat.
      </p>

      <p>
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Ayam sp. z o.o., Kwiatowa 1D/11, 71-045 Szczecin, Polen; support@ayam.green) mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
      </p>

      <p>
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
      </p>

      <h4>Folgen des Widerrufs</h4>
      <p>
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
      </p>

      <p>
      Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an Ayam sp. z o.o. Kwiatowa 1D/11, 71-045 Szczecin, Polen. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren. Sie müssen für einen etwaigen Wertverlust der Waren auch aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
      </p>

      <h3>Muster-Widerrufsformular</h3>
      (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es getrennt von der Ware an unsere untenstehende Anschrift.)

      <p>
      – An Ayam sp. z.o.o, Kwiatowa 1D/11, 71-045 Szczecin, Polen, support@ayam.green
      <br></br>
      – Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)
      <br></br>
      – Bestellt am (*)/erhalten am (*)
      <br></br>
      – Name des/der Verbraucher(s)
      <br></br>
      – Anschrift des/der Verbraucher(s)
      <br></br>
      – Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
      <br></br>
      – Datum
      <br></br>
      (*) Unzutreffendes streichen.
      </p>
    </div>
  );
}

export default AGB;
