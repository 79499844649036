import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import AGB from './AGB.js';

function App() {
  return (
    <div>
    <Router>
      <Routes>
        <Route path="/AGB" element={<AGB />}/>
        <Route path="*" element={<Navigate to="/AGB" replace={true} />}/>
      </Routes>
    </Router>
    </div>
  );
}

export default App;
